@font-face {
    font-family: SofiaProSemiBold;
    src: url('~assets/fonts/Sofia-Pro/SofiaProSemiBold.ttf');
}
@font-face {
    font-family: SofiaProBold;
    src: url('~assets/fonts/Sofia-Pro/SofiaProBold.ttf');
}
@font-face {
    font-family: SofiaProBoldMedium;
    src: url('~assets/fonts/Sofia-Pro/SofiaProMedium.ttf');
}
@font-face {
    font-family: SofiaProRegular;
    src: url('~assets/fonts/Sofia-Pro/SofiaProRegular.ttf');
}
@font-face {
    font-family: SofiaProLight;
    src: url('~assets/fonts/Sofia-Pro/SofiaProLight.ttf');
}
@font-face {
	font-family: 'fontello';
	src: url('~assets/fonts/fontello/fontello.eot?a8d49310');
	src: url('~assets/fonts/fontello/fontello.eot?a8d49310#iefix') format('embedded-opentype'),
	     url('~assets/fonts/fontello/fontello.woff2?a8d49310') format('woff2'),
	     url('~assets/fonts/fontello/fontello.woff?a8d49310') format('woff'),
	     url('~assets/fonts/fontello/fontello.ttf?a8d49310') format('truetype'),
	     url('~assets/fonts/fontello/fontello.svg?a8d49310#fontello') format('svg');
	font-weight: normal;
	font-style: normal;
}
