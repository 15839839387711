
@use "igniteui-angular/theming" as *;

/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

//import fonts
@import './app/theme/styles/fonts';

// themes - our custom or/and out of the box themes
@import '../src/app/theme/styles/theme';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/utilities';

// loading progress bar theme
@import './app/theme/styles/pace-theme'; 
/**           ↑
 * This extension */
@import '@nebular/auth/styles/globals';

@import './app/theme/styles/layout';
@import './app/theme/styles/overrides';

// Material
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "material-icons/iconfont/material-icons.css";

@import "./app/theme/styles/mixin-element";

@include core();
@include typography($font-family: $material-typeface, $type-scale: $material-type-scale);
@include theme($default-palette);

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

body {
  font-family    : SofiaProRegular, sans-serif;
  font-size: 14px;
}

html body {
  font-family    : SofiaProRegular, sans-serif;
  font-size: 14px;
}
.nb-theme-default nb-stepper .header .step.selected .label {
  color: var(--color-semantic-information-solid-s-2) !important;
}

.nb-theme-default nb-stepper .header .step.completed .label{
  color: var(--color-semantic-information-solid-s-2) !important;
}


.p-component {
  font-family    : SofiaProRegular, sans-serif;
  font-size: 14px;
  color: var(--color-neutral-carbon-solid-c-mid-6-2);
}


.p-datatable .p-datatable-thead > tr > th {
  font-family    : SofiaProBoldReal, sans-serif;
  font-weight: 500;
  padding: 0.7rem 0.7rem;
}


.p-datatable .p-datatable-tbody > tr {
  color: var(--color-neutral-carbon-solid-c-mid-6-2);
}

.p-paginator .p-dropdown {
  height: 100%;
}
.p-dropdown {
  height: 40px;
}


.p-checkbox .p-checkbox-box .p-checkbox-icon {
  position: relative;
  bottom: 5px;
  right: 5px;
}


.p-dropdown:not(.p-disabled).p-focus {
  border-color: var(--color-semantic-information-solid-s-3);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #0075be;
  background: rgb(0 117 190 / 12%);
}


.p-multiselect {
  height: 34px;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding: 0.5rem 0.5rem !important;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0rem 1rem !important;
}

.p-button {
  background: var(--color-primary-blue-solid-b-600-base);
  padding: 0.6rem 1rem;
  color: white;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: var(--color-secundary-green-solid-g-600-ba);
}


.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: var(--color-secundary-green-solid-g-600-ba);
}

.p-datatable-frozen-view {
  border-right: 1px solid #e4e4e4;
}

.p-datatable .p-sortable-column .p-column-title, .p-datatable .p-sortable-column .p-sortable-column-badge, .p-datatable .p-sortable-column .p-sortable-column-icon {
  font-size: 12px;
}

.p-button .p-button-icon-left {
  font-size: 12px;
}

.main-container-fixed {
  position: unset !important;
  max-height: calc(100vh - 6.75rem);
}

.nb-theme-default nb-sidebar {
  overflow: auto;
  border-radius: 16px;
  position: relative;
  bottom: 61px;
  margin-left: 10px;
  width: 198px;
  max-height: calc(100vh - 2.75rem);
}

.nb-theme-default nb-sidebar.expanded {
  width: 198px;
}

.nb-theme-default nb-card {
  border-radius: 16px;
  position: relative;
}


.nb-theme-default .menu-sidebar .scrollable {
  padding-top: 1.40rem;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  color: white;
  background: #34a6d3;
}

.p-datatable .p-datatable-header {
  color: #014583;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:focus {
  background-color: rgb(76 181 63 / 12%);
}

.p-datatable .p-datatable-tbody > tr:not(.p-highlight):focus {
  background-color: #edf1f7;
}

.p-datatable-resizable .p-resizable-column.p-frozen-column {
  position: sticky !important;
}


.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #cfcfcf;
  color: rgba(0,0,0,.87);
  cursor: pointer;
}

.p-sidebar {
  background: transparent;
  border-radius: 16px;
  box-shadow: none;
}

.p-sidebar-view {
  position: absolute !important;
}

.p-sidebar-hide {
  transition: transform 0s !important;
}


.p-sidebar-right {
  width: 30rem !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: rgba(76, 181, 63, 0.12);
  border-color: rgba(76, 181, 63, 0.12);
  color: #83bc20;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #34a6d3;
  background: #34a6d3;
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #0077b6;
  background: #0077b6;
}


.p-selectable-row .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #ffffff;
  background: #34a6d3;
}

.p-datatable-selectable-row .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #ffffff;
  background: #34a6d3;
}


.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #0075be;
  background: rgb(0 117 190 / 12%);
}


.p-button:not(.p-button-outlined):not(.p-button-text) .p-button-label {
  color: white;
}

.p-button:enabled:hover {
  background: rgba(63, 81, 181, 0.92);
  color: #ffffff;
  border-color: transparent;
}
.p-button:enabled:active {
  background: rgba(63, 81, 181, 0.68);
  color: #ffffff;
  border-color: transparent;
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: var(--color-primary-blue-solid-b-600-base);
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover {
  background: rgba(63, 81, 181, 0.04);
  color: var(--color-primary-blue-solid-b-600-base);
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:active {
  background: rgba(63, 81, 181, 0.16);
  color: var(--color-primary-blue-solid-b-600-base);
  border: 1px solid;
}
.p-button.p-button-text {
  background-color: transparent;
  color: var(--color-primary-blue-solid-b-600-base);
  border-color: transparent;
}
.p-button.p-button-text:enabled:hover {
  background: rgba(63, 81, 181, 0.04);
  color: var(--color-primary-blue-solid-b-600-base);
  border-color: transparent;
}
.p-button.p-button-text:enabled:active {
  background: rgba(63, 81, 181, 0.16);
  color: var(--color-primary-blue-solid-b-600-base);
  border-color: transparent;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: rgb(76 181 63 / 12%);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #83bc20;
}

.p-inputswitch .p-inputswitch-slider:before {
  width: 1rem;
  height: 1rem;
  margin-top: -0.55rem;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: rgb(63 181 71 / 35%);
}

.p-inputswitch {
  width: 2.3rem;
  height: 0.7rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.7rem 0.7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-datatable-frozen-view 

.p-datatable .p-datatable-tbody > tr>td:hover {
  overflow: auto;
  text-overflow: initial;
}


.p-inputtext {
  font-family: SofiaProRegular, sans-serif;
  font-size: 14px;
  padding: 0.7rem 0.7rem;
  border-radius: 8px;
  border: solid 1px #c8ccce;
}

.p-inputtext:enabled:focus {
  box-shadow: inset 0 0 0 1px var(--color-semantic-information-solid-s-3), inset 0 0 0 1px var(--color-semantic-information-solid-s-3), inset 0 0 0 1px var(--color-semantic-information-solid-s-3), inset 0 0 0 1px var(--color-semantic-information-solid-s-3);
  border-color: var(--color-semantic-information-solid-s-3);
}

.p-datepicker table td.p-datepicker-today > span {
  border-color: var(--color-semantic-information-solid-s-3);
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #83bc20;
  background: rgba(76, 181, 63, 0.12);
}



.p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
  text-transform: capitalize !important;
  padding: 0.5rem 1.2rem !important;
}

.p-datepicker table td > span.p-highlight {
  background: rgba(76, 181, 63, 0.12);
  color: #83bc20;
}

.p-input-icon-right > em:last-of-type {
  right: 1rem;
  color: rgba(0, 0, 0, 0.6);
}
.p-input-icon-left>em, .p-input-icon-right>em {
  position: absolute;
  top: 50%;
  margin-top: -.5rem;
}

.p-input-icon-right > button:last-of-type {
  right: 0rem;
  color: rgba(0, 0, 0, 0.6);
}
.p-input-icon-left>button, .p-input-icon-right>button {
  position: absolute;
  top: 22%;
  margin-top: -.5rem;
}


.p-input-icon-left > em:first-of-type {
  left: 1rem;
  color: rgba(0, 0, 0, 0.6);
}

.p-field-checkbox > label, .p-field-radiobutton > label {
  padding-top: 8px;
}

.p-field {
  margin-bottom: 1rem;
}

.p-button.p-fileupload-choose {
  height: 35px;
}


.p-dialog {
  border-radius: 16px;
  .p-dialog-footer {
    border-radius: 0 0 16px 16px;
  }
  .p-dialog-header {
    border-radius: 16px 16px 0 0;
    color: #014583 !important;
    padding: 2rem 1rem 1rem 1rem !important;
    .p-dialog-title {
      width: 100%;
  }
  }
  .p-dropdown {
      width: 100%;
  }
}

.p-datatable-tbody > tr.p-highlight .p-checkbox .p-checkbox-box.p-highlight {
  border-color: white !important;
}

.p-button.p-button-icon-only.p-button-rounded {
  height: 2.5rem;
  width: 2.5rem;
}

.p-float-label input:focus ~ label, .p-float-label .p-inputwrapper-focus ~ label {
  color: #0075be !important;
}

.p-overlaypanel .p-overlaypanel-close {
  background: #0077b6;
}

.fp-h1 {
  font-family   : SofiaProSemiBold, sans-serif !important;
  font-stretch  : normal !important;
  font-style    : normal !important;
  font-weight   : 100 !important;
  line-height   : 1.33 !important;
  text-align    : center !important;
  color         : var(--fp-title) !important;
  font-size     : 1.75rem !important;
  letter-spacing: normal !important;
}

.fp-tittle-loader {
  font-family: SofiaProSemiBold, sans-serif;
  font-stretch: normal;
  font-style: normal;
  font-weight: 100;
  line-height: 1.33;
  text-align: center;
  color: white !important;
  text-shadow: 0px 1px 1px black;
  font-size: 1.75rem !important;
  letter-spacing: normal;
}


.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #0077b6 !important;
}

.p-tooltip {
  max-width: 25.5rem !important;
}


.nb-theme-default nb-sidebar nb-menu {
  padding: 36px 0 0;
}

@media (max-width: 1200px) {
  .nb-theme-default nb-layout .layout .layout-container .content .columns nb-layout-column {
    padding: 1.5rem 0rem 0rem !important;
}

.nb-theme-default nb-sidebar.compacted {
  width: 0 !important;
  padding: 0;
}

.nb-theme-default nb-sidebar.compacted.left.fixed ~ .content {
  margin-left:  0 !important;
}
}


.nb-theme-default nb-menu .menu-item > .menu-items > .menu-item a.active {
  box-shadow: inset 4px 0px 1px 0px #d5ff67;
}


.nb-theme-default nb-menu .menu-item > .menu-items > .menu-item a {
  flex-direction: column;
}


:root {
  --color-alert-info-solid-b-ibase      : #00a6fb;
  --color-tertiary-white-solid-w-50     : #f9f9f9;
  --cards-solid-card-solid-elevation-0  : #ffffff;
  --color-neutral-gray-solid-g-600      : #9e9e9e;
  --color-primary-blue-solid-b-800      : #5a78b0;
  --color-primary-blue-solid-b-50       : #eff0f4;
  --color-tertiary-white-solid-w-200    : #faf9f9;
  --color-primary-blue-solid-b-400      : #34a6d3;
  --color-alert-danger-solid-r-dbase    : #ff3144;
  --color-secundary-bluedark-solid-bd-50: #ecedee;
  --color-alert-success-solid-g-sbase   : #1c6d29;
  --color-primary-blue-solid-bbase      : #32579e;
  --button-specs-state-primary-normal   : rgba(255, 255, 255, 0);
  --evergreen                           : #121212;
  --color-primary-blue-solid-b-600-base : #0077b6;
  --color-secundary-green-solid-g-600-ba: #80ba17;
  --color-semantic-warning-solid-sw-50  : #fef6cb;
  --color-semantic-information-solid-s-2: #0075be;
  --color-semantic-information-solid-s-3: #3abeea;
  --color-neutral-carbon-solid-c-light-6: #ebebeb;
  --color-semantic-error-solid-se-600-s : #b1120e;
  --color-semantic-warning-solid-sw-400 : #fbcb3e;
  --color-semantic-success-solid-ss-400 : #57b255;
  --color-input-border-gray             : #c8ccce;
  --color-neutral-carbon-solid-c-mid-6-2: #555555;
  --fp-border-radius                    : 1rem;
  --fp-title                            : #014583;
  --fp-body-one                         : #121212;
  --fp-modal-text                       : #677379;
  --fp-modal-link                       : #55a630;
  --fp-modal-background                 : #000000;
  --fp-color-black                      : #121212;
  --fp-btn-primary-hover                : #1b84bd;
  --fp-btn-primary-focus                : #015e91;
  --fp-btn-primary-active               : #015e91;
  --fp-btn-secundary-focus              : #cdf6fb;
  --fp-btn-ghost-hover                  : #20710d;
  --fp-btn-ghost-focus                  : #0c4921;
  --fp-btn-ghost-disabled               : #d9d9d9;
  --color-semantic-information-solid-si : #cafafd;
  --color-semantic-error-1              : #fce2ce;
  --color-semantic-error-2              : #b3182d;
  --color-semantic-warning-solid-sw-800 : #905b00;
  --fp-color-active                     : #d5ff67;
  --color-semantic-warning-solid-sw-60-2: #d69300;
  --fp-btn-background-tertiary          : #c9f6fb;
}