@mixin fp-h1 {
  font-family   : SofiaProSemiBold, sans-serif;
  font-size     : 1.5rem;
  font-stretch  : normal;
  font-style    : normal;
  font-weight   : 100;
  line-height   : 1.33;
  letter-spacing: 0.40px;
  text-align    : center;
  color         : var(--fp-title);

  @include for-desktop-up {
    font-size     : 1.75rem;
    letter-spacing: normal;
  }

  @include for-tablet-portrait-up {
    font-size     : 1.75rem;
    line-height   : 1.14;
    letter-spacing: normal;
  }
}
@mixin fp-body-one {
  font-family   : SofiaProLight, sans-serif;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.5;
  letter-spacing: 0.44px;
  font-size     : 1rem;
  font-weight   : 700;
  text-align    : center;
  // width: initial;
  color         : var(--fp-body-one);
  // @include for-tablet-portrait-up {
  //     width: initial;
  // }

  // @include for-desktop-up {
  //     width: initial;
  // }
}
