:root {
  --animate-duration: 1.5s;
  --animate-delay   : 1.5s;
}

%animated_skeleton {
  -webkit-animation-duration : var(--animate-duration);
  animation-duration         : var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode        : both;
}

@-webkit-keyframes skeleton {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@keyframes skeleton {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@mixin skeleton {
  @extend %animated_skeleton;
  background       : linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size  : 400% 100%;
  -webkit-animation: 1.4s infinite skeleton;
  animation        : 1.4s infinite skeleton;
}