@import "mixin-base";
@import "mixin-btn";
@import "mixin-titles";
@import "mixin-skeleton";

// @mixin circle($width, $height, $background, $borderRadius) {
//   width                : $width;
//   height               : $height;
//   -moz-border-radius   : $borderRadius;
//   -webkit-border-radius: $borderRadius;
//   border-radius        : $borderRadius;
//   background           : $background;
// }

.fp-btn-primary {
  @include fp-btn-primary;
}

.fp-btn-secondary {
  @include fp-btn-secondary;
}

.fp-btn-tertiary {
  @include fp-btn-tertiary;
}

.fp-btn-ghost {
  @include fp-btn-ghost;
}

.fp-btn-link {
  @include fp-link-button;
}

.fp-btn-white {
  @include fp-btn-white;
}

.fp-btn-fap {
  @include fp-btn-fap;
}


.fp-h1 {
  @include fp-h1
}

.fp-body-one {
  @include fp-body-one
}

button {
  position: relative;
}

button:after {
  content : '';
  position: absolute;
  width   : 100%;
  height  : 100%;
  display : block;
  left    : 0;
  top     : 0;
}

.fp-card-detail {
  border-radius   : 8px;
  box-shadow      : 0 1px 2px 0 rgba(33, 33, 33, 0.14);
  background-color: var(--cards-solid-card-solid-elevation-0);
  margin-bottom   : 24px;

  @include for-tablet-portrait-up {
    margin-bottom: 1rem;
  }

  @include for-desktop-up {
    margin-bottom: 1rem;
  }

  .fp-card-detail-header {
    span {
      color         : #014583;
      font-family   : SofiaProRegular, sans-serif;
      font-size     : 1rem;
      font-stretch  : normal;
      font-style    : normal;
      line-height   : 1.5;
      letter-spacing: 0.13px;
    }

    border-bottom : solid 1px var(--color-secundary-bluedark-solid-bd-50);
    padding-top   : 1.5rem;
    padding-left  : 1.5rem;
    padding-right : 1.5rem;
    padding-bottom: 0.9375rem;
  }

  hr {
    margin: 6px 23px 9px 23px;
    border: solid 1px var(--color-secundary-bluedark-solid-bd-50);
  }

  .fp-card-detail-body {
    font-size      : 14px;
    font-stretch   : normal;
    font-style     : normal;
    line-height    : 1.71;
    letter-spacing : 0.22px;
    padding-top    : 17px;
    padding-bottom : 17px;
    padding-left   : 1.5rem;
    padding-right  : 1.5rem;
    display        : flex;
    justify-content: space-between;
    color          : var(--color-neutral-carbon-solid-c-mid-6-2);

    &.r-1 {
      padding-right: 0.5rem;
    }

    div.description {
      width         : 70%;
      display       : flex;
      flex-direction: column;
      text-align    : left;

      span.bold {
        padding-bottom: 9px;
        font-weight   : bold;
        font-family   : SofiaProRegular, sans-serif;
        color         : var(--fp-color-black);
      }

      .nospace {
        padding-bottom: 0px !important;
      }

      .spacing {
        padding-top   : 8px !important;
        padding-bottom: 7px !important;
      }

      span {
        padding-bottom: 8px;
        width         : 100%;
        word-wrap     : break-word;
      }
    }

    div.value {
      margin-top    : auto;
      margin-bottom : auto;
      text-align    : right;
      display       : flex;
      flex-direction: column;

      &.top {
        margin-top: 0;
      }

      span.total {
        font-family   : SofiaProSemiBold, sans-serif;
        font-size     : 18px;
        font-stretch  : normal;
        font-style    : normal;
        line-height   : 1.33;
        letter-spacing: 0.15px;
        color         : var(--color-primary-blue-solid-b-600-base);
      }

      span.bold {
        padding-bottom: 9px;
        font-weight   : bold;
        font-family   : SofiaProSemiBold, sans-serif;
        color         : var(--fp-color-black);
      }

      .nospace {
        padding-bottom: 0px !important;
      }

      .spacing {
        padding-top   : 8px !important;
        padding-bottom: 7px !important;
      }

      span {
        padding-bottom: 8px;
      }
    }
  }
}

.skeleton {
  @include skeleton
}